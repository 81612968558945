/* ✅ New Color Scheme */
.green-bg { background-color: #008000 !important; color: white !important; } /* ✅ Buy (Green) */
.yellow-bg { background-color: #FFD700 !important; color: black !important; } /* ⚠️ Buy Less (Yellow) */
.red-bg { background-color: #8B0000 !important; color: white !important; } /* ❌ Don't Buy (Dark Red) */
.white-bg { background-color: white !important; color: black !important; }

/* 🌙 Dark Mode - Adjust Colors */
.dark-mode .green-bg { background-color: #0f4f0f !important; color: #d1fadf !important; } /* Dark Green */
.dark-mode .yellow-bg { background-color: #8b8000 !important; color: #fffacd !important; } /* Dark Yellow */
.dark-mode .red-bg { background-color: #600000 !important; color: #ffb3b3 !important; } /* Dark Red */

/* 🔹 Style Table */
tr.green-bg td, tr.yellow-bg td, tr.red-bg td {
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f4f4f4;
}

/* ✅ Dark Mode Table Fix */
.dark-mode th {
  background-color: #222 !important;
  color: #ffffff !important;
}

/* 🔹 Style Legend */
.legend {
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
}

.legend-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 1px solid black;
}
