/* General Styles */
.App {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: Arial, sans-serif;
}

/* Header Styles */
.header {
  height: 60px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
}

/* Main Content Styles */
.main-content {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: middle;
}

thead {
  background-color: #f2f2f2;
  color: black;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

/* Sidebar Styles */
.header nav {
  display: flex;
  justify-content: center;
  background-color: #333;
}

.sidebar-button {
  padding: 10px 20px;
  background-color: #808080;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-right: 10px;
  display: inline-block;
}

.sidebar-button:hover {
  background-color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }
  
  .main-content {
    padding: 10px;
  }

  table {
    font-size: 14px;
  }
}

/* 🌙 Dark Mode - Global Fix */
.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

/* 🌙 Fix Top Bar (Header) in Dark Mode */
.dark-mode .header {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border-bottom: 1px solid #444;
}

/* 🌙 Fix Sidebar Buttons in Dark Mode */
.dark-mode .sidebar-button {
  background-color: #333;
  color: white;
}

.dark-mode .sidebar-button:hover {
  background-color: #555;
}

/* 🌙 Dark Mode Toggle Button */
.dark-mode-toggle {
  padding: 8px 16px;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.dark-mode .dark-mode-toggle {
  background-color: #bbb;
  color: black;
}

.dark-mode .dark-mode-toggle:hover {
  background-color: #ddd;
}

/* 🌙 Dark Mode - Fix for All Tables */
.dark-mode table {
  background-color: #1e1e1e !important;
  color: #e0e0e0 !important;
  border-collapse: collapse;
  width: 100%;
}

/* 🌙 Dark Mode - Fix Table Headers */
.dark-mode thead {
  background-color: #333 !important;
  color: #ffffff !important;
}

/* 🌙 Dark Mode - Fix Individual Header Cells */
.dark-mode th {
  background-color: #444 !important;
  color: #ffffff !important;
  border: 1px solid #666 !important;
  padding: 10px;
}

/* 🌙 Dark Mode - Fix Table Rows */
.dark-mode tbody tr:nth-child(odd) {
  background-color: #181818 !important;
}

.dark-mode tbody tr:nth-child(even) {
  background-color: #222 !important;
}

.dark-mode tbody tr:hover {
  background-color: #333 !important;
}

/* 🌙 Dark Mode - Fix Table Borders */
.dark-mode th, 
.dark-mode td {
  border: 1px solid #555 !important;
  padding: 10px;
}

/* 🌙 Ensure Table Cells (TD) are Readable */
.dark-mode td {
  color: #e0e0e0 !important;
}

/* 🌙 Dark Mode - Fix Highlighted Cells */
.dark-mode td.highlighted {
  background-color: #555 !important; /* Medium gray highlight */
  color: #ffffff !important; /* Ensure readable text */
  font-weight: bold;
}

/* 🌙 Dark Mode - Fix Hovered Cells */
.dark-mode tbody tr:hover td {
  background-color: #444 !important;
  color: #ffffff !important;
}
/* 🌙 Dark Mode - Fix Positive (Green) Highlighted Cells */
.dark-mode td.positive {
  background-color: #166534 !important; /* Darker green */
  color: #d1fadf !important; /* Light green text */
  font-weight: bold;
}

/* 🌙 Dark Mode - Fix Negative (Red) Highlighted Cells */
.dark-mode td.negative {
  background-color: #7f1d1d !important; /* Darker red */
  color: #ffb3b3 !important; /* Light red text */
  font-weight: bold;
}

/* Light Mode */
.positive-change {
  background-color: #ccffcc; /* Soft green */
  color: black;
}

.negative-change {
  background-color: #ffcccc; /* Soft red */
  color: black;
}

/* 🌙 Dark Mode - Fix for Highlighted Cells */
.dark-mode .positive-change {
  background-color: #166534 !important; /* Darker green */
  color: #d1fadf !important; /* Light green text */
  font-weight: bold;
}

.dark-mode .negative-change {
  background-color: #7f1d1d !important; /* Darker red */
  color: #ffb3b3 !important; /* Light red text */
  font-weight: bold;
}
